import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Observable } from 'rxjs';

//Services
import {CategoryService} from './category.service';
import {DeviceService} from '../home/devices/device.service';
import {ManualService} from '../manual/manual.service';

//Models
import {IDevice} from '../home/devices/device';
import {IManualPreview} from '../manual/manual-preview';
import {IManual} from '../manual/manual-preview';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [CategoryService, DeviceService, ManualService]
})
export class CategoryComponent implements OnInit {
  categoryName: string = "";
  devices: IDevice[];
  manualsPreviews: IManualPreview[];
  manuals: IManual[];
  searchQuery: string;
  selectedSearchQuery: string;
  selectedCategory: string = "";
  selectedDevices: string[] = [];

  constructor(private route: ActivatedRoute,
    private categoryService: CategoryService,
    private deviceService: DeviceService,
    private _manualsService: ManualService) {
    scroll(0, 0);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {

      this.deviceService.getDevicesFromAPI()
        .subscribe(
        res => {
          this.devices = res.data;
          this.setSearchQueryOnInit();
          this.setSelectCategoryOnInit(params['id'])
          this.selectDeviceOnInit(params['deviceId']);
          localStorage.removeItem("searchParams"); //for clear cache.
          this.searchManuals();
        },
        err => console.log(<string>err),
        () => {

        });
    });
  }

  isSelectCategory(categoryCode: string) {
    return categoryCode == this.selectedCategory;
  }

  setSelectCategoryOnInit(categoryId: string) {
    if (categoryId != undefined) {
      this.categoryOnClick(categoryId);
    }
  }

  setSearchQueryOnInit() {
    var queryObject = JSON.parse(localStorage.getItem("searchParams"));
    if (queryObject != undefined && queryObject != 'undefined') {
      this.searchQuery = queryObject.query;
    }
    else {
      this.searchQuery = "";
    }
    this.selectedSearchQuery = this.searchQuery;


  }

  selectDevicesOnInit() {
    var queryObject = JSON.parse(localStorage.getItem("searchParams"));
    if (queryObject != undefined && queryObject.devices != undefined) {
      for (let id of queryObject.devices) {
        for (let device of this.devices) {
          if (device._id == id) {
            this.selectedDevices.push(device._id);
            device.selected = true;
          }
        }
      }
    }
  }

  selectDeviceOnInit(selectedDeviceId: string) {
    if (selectedDeviceId != undefined)
      for (let device of this.devices) {
        if (device._id == selectedDeviceId) {
          this.selectedDevices.push(device._id);
          device.selected = true;
        }
      }
    else
      this.selectDevicesOnInit();
  }


  deviceOnClick(id: string) {
    for (let device of this.devices) {
      if (device._id == id) {
        var index = this.selectedDevices.indexOf(device._id, 0);
        if (device.selected && index > -1) {
          this.selectedDevices.splice(index, 1);
          device.selected = false;
        }
        else {
          this.selectedDevices.push(device._id);
          device.selected = true;
        }
      }
    }
    this.searchManuals();
  }

  categoryOnClick(id: string) {
    if (this.selectedCategory == id) {
      this.selectedCategory = "";
    }
    else {
      this.selectedCategory = id;
    }
    this.searchManuals();
  }

  searchOnClick() {
    this.selectedSearchQuery = this.searchQuery;
    this.searchManuals();
  }

  resetSearch() {
    this.selectedSearchQuery = "";
    this.searchQuery = "";
    this.selectedDevices = [];
    if (this.devices)
      for (let device of this.devices) {
        device.selected = false;
      }
    this.searchManuals();
  }

  searchManuals() {
    this._manualsService.getManualsList({ categoryCode: this.selectedCategory, devices: this.selectedDevices, searchQuery: this.selectedSearchQuery })
      .subscribe(
      results => this.manuals = results.data,
      error => {
        this.manuals = null;
        console.log(`Manual error: ${error}`)
      }
      );
  }

  getHeaderText() {
    if (this.selectedCategory == undefined || this.selectedCategory == "") {
      return this.categoryService.getCategoryNameByCode("all");
    }
    else {
      return this.categoryService.getCategoryNameByCode(this.selectedCategory);
    }
  }



}

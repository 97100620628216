import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

//Featured Modules
import {HomeModule} from './home/home.module';
import {CategoryModule} from './category/category.module';
import {ManualModule} from './manual/manual.module';
import {RequestModule} from './request/request.module';
import {RequestMaintenanceModule} from './request-maintenance/request-maintenance.module';
import {ContactModule} from './contact/contact.module';
import {TermsAndConditionsModule} from './terms-and-conditions/terms-and-conditions.module';
import {SignInModule} from './sign-in/sign-in.module';
//Secure Component
import { AppComponent } from './app.component';

//Translate
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// Common
import { AuthGuard } from './../common/auth.guard';

import { SimpleNotificationsModule }  from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule,
    HomeModule,
    CategoryModule,
    ManualModule,
    RequestMaintenanceModule,
    ContactModule,
    TermsAndConditionsModule,
    SignInModule,
    RequestModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })    
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

import {ICategory} from "../category/category";
import {SafeHtml, SafeUrl, SafeStyle} from '@angular/platform-browser';

export interface IManualPreview {
  _id: number;
  title: string;
  shortDescription: string;
  categories: ICategory[];
}

import {IManualStep} from './manual-step';

export interface IManual {
  _id: string;
  title: string;
  author: string;
  isPublic: boolean;
  creationDate: Date;
  devices: any[];
  steps: IManualStep[];
  categories: string[];
  tools: string[];
  parts: string[];
  manualPoster: string;
  manualPosterPreview: SafeStyle;
  relatedManuals: string[];
}

export class Manual implements IManual {
  public _id: string;
  public title: string;
  public author: string;
  public isPublic: boolean;
  public creationDate: Date;
  public devices: any[];
  public steps: IManualStep[];
  public categories: string[];
  public tools: string[];
  public parts: string[];
  public manualPoster: string;
  public manualPosterPreview: SafeStyle;
    public relatedManuals: string[];

  constructor(){
    this._id = "",
    this.title = "",
    this.author = "",
    this.isPublic = false,
    this.creationDate = new Date(),
    this.devices = [],
    this.steps = [],
    this.categories = [],
    this.tools = [],
    this.parts = [],
    this.manualPoster = "",
    this.manualPosterPreview = ""
    this.relatedManuals = [];
  }

  public getFormatDate() {
    return this.creationDate.toISOString().slice(0,10).replace(/-/g,"");
  }
}

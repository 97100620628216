import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { RequestMaintenanceService } from '../request-maintenance/request-maintenance.service';
import { NotificationsService } from 'angular2-notifications';

import { IRequest } from '../request-maintenance/request';
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  providers: [
    RequestMaintenanceService,
    NotificationsService]
})
export class RequestComponent implements OnInit {
  request: IRequest;
  isSubmitted: boolean = false;
  public alertsOptions = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    showProgressBar: false,
    position: ['right', 'top'],
    maxStack: 1
  };
  constructor(private _requestService: RequestMaintenanceService,
    private _notificationsService: NotificationsService) {

    scroll(0, 0);

    this.request = {
      _id: '',
      deviceSerialNumber: '',
      deviceType: '',
      address: '',
      therapist: '',
      date: '',
      additionalInformations: '',
      computerDeviceSerialNumber: '',
      appVersion: '',
      librariesVerion: {},
      appScreenImage: '',
      appCurrentView: '',
      exceptionMessage: {},
      status: 0,
      isUpdated: false
    }

  }
  onSubmit() {
    this.isSubmitted = true;
    this._requestService.create(this.request)
      .subscribe(
      results => console.log(results),
      error => console.log(<any>error)
      );
  }
  ngOnInit() {

  }

}

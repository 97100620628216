import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SimpleNotificationsModule }  from 'angular2-notifications';

import { RequestRoutingModule } from './request-routing.module';
import { RequestComponent } from './request.component';

@NgModule({
  imports: [
    CommonModule,
    RequestRoutingModule,
    FormsModule,
    SimpleNotificationsModule
  ],
  declarations: [RequestComponent]
})
export class RequestModule { }

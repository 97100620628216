import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {RequestMaintenanceService} from './request-maintenance.service';
import { ActivatedRoute, Params }   from '@angular/router';
import { NotificationsService } from 'angular2-notifications';

import { IRequest } from './request';
@Component({
  selector: 'app-request-maintenance',
  templateUrl: './request-maintenance.component.html',
  styleUrls: ['./request-maintenance.component.scss'],
  providers: [
    RequestMaintenanceService,
    NotificationsService]
})
export class RequestMaintenanceComponent implements OnInit {
  request: IRequest;
  public alertsOptions = {
    timeOut: 5000,
    lastOnBottom: true,
    clickToClose: true,
    showProgressBar: false,
    position: ['right', 'top'],
    maxStack: 1
  };

  constructor(private route: ActivatedRoute,
    private _requestService: RequestMaintenanceService,
    private _notificationsService: NotificationsService) { }

  ngOnInit() {

this.route.params.subscribe(params => {

  this._requestService.getRequestFromAPI(params["id"])
    .subscribe(
    results => this.request = results,
    error => console.log(<any>error)
    );

});


  }
  onSubmit(){

    this._requestService.update(this.request)
       .then((results) => this.request = results);

    this.request.isUpdated = true;
    this._notificationsService.success(
      'Success!',
      'Thank You for send request data.')

  }

  getStatusText(){
    var message = "";
    switch(this.request.status)
    {
      case 0:
      message = "Brak reakcji";
      break;
    }
    return message;
  }

}

import { Injectable } from '@angular/core';
import {ICategory} from './category';


@Injectable()
export class CategoryService {
  //static list with categories data
  private categories: ICategory[] = [
    {code: "quick-start", name:"Quick Start"},
    {code: "therapy", name:"Therapy"},
    {code: "diagnosis", name:"Diagnosis"},
    {code: "extensions", name:"Extensions"},
    {code: "tips-and-tricks", name:"Tips & Tricks"},
    {code: "troubleshooting", name:"Trouble Shooting"},
    {code: "marketing", name:"Marketing"},
    {code: "success-stories", name:"Success Stories"},
    {code: "all", name:"All Categories"}
  ]

  constructor() { }

  getCategoryNameByCode(code: string){
    var category = this.categories.find(function(category){
      return category.code == code;
    });

    return category.name;
  }



}

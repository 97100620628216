import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  AfterViewInit,
  AfterContentInit,
  ViewChild,
} from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";

declare var jQuery: any;

import { NgForm } from "@angular/forms";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [NotificationsService],
})
export class AppComponent
  implements OnInit, OnChanges, AfterContentInit, AfterViewInit
{
  email: string = "";
  emailForm: NgForm;
  @ViewChild("emailForm") currentForm: NgForm;

  constructor(private _notificationsService: NotificationsService, private translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }  

  ngOnInit() {
    this.loadMobileMenu();
  }

  ngOnChanges() {}
  ngAfterContentInit() {}
  ngAfterViewInit() {}

  /*OPTIONS FOR NOTIFICATIONS*/
  public options = {
    timeOut: 500,
    clickToClose: true,
    showProgressBar: false,
    position: ["right", "top"],
  };

  submitForm(form: any, event: Event): void {
    event.preventDefault();
    if (form && jQuery(".email").val() !== "") {
      this._notificationsService.success(
        "SUCCESS",
        "Thank You for subcribe us!"
      );
    } else {
      this._notificationsService.error("ERROR", "Email value is not correct!");
    }
    jQuery(".email").val("");
  }

  loadMobileMenu() {
    setTimeout(function () {
      jQuery(".sectionCompany__showMobileMenu").click(function () {
        jQuery("body").addClass("show-menu");
        return false;
      });

      jQuery(".sectionMobileMenu a").click(function () {
        jQuery("body").removeClass("show-menu");
        return false;
      });

      jQuery(".sectionMobileMenu .sectionMobileMenu__close").click(function () {
        jQuery("body").removeClass("show-menu");
        return false;
      });
    }, 200);
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form: FormGroup;

  constructor(private _formBuilder: FormBuilder) {
    scroll(0, 0);
    this.form = _formBuilder.group({
      name:          ['', Validators.required],
      organisation:   [''],
      mail:          ['', Validators.required],
      phone:          ['', Validators.pattern('[0-9]')],
      message:        ['',Validators.required],
      copyMail:       [true]
    });
  }

  ngOnInit() {
  }

  sendRequest(event) {
    // console.log(event);
    console.log(this.form.valid);
    console.log(this.form.value);
    // console.log(this.form.controls.name.value);
  }

}

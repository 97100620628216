import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HintsComponent } from './hints/hints.component';
import { FrequentlyManualsComponent } from './frequently-manuals/frequently-manuals.component';
import { FrequentlyManualsItemComponent } from './frequently-manuals/frequently-manuals-item/frequently-manuals-item.component';
import { HintsElementComponent } from './hints/hints-element/hints-element.component';
import { DevicesListComponent } from './devices/devices-list/devices-list.component';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule
  ],
  declarations: [
    HomeComponent,
    HintsElementComponent,
    HintsComponent,
    FrequentlyManualsItemComponent,
    FrequentlyManualsComponent,
    DevicesListComponent
  ],
  exports:[HomeRoutingModule]
})
export class HomeModule { }

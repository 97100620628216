import { Component, OnInit, Input} from '@angular/core';
import {IFrequentlyManual, FrequentlyManual} from '../frequently-manual';

@Component({
  selector: 'app-frequently-manuals-item',
  templateUrl: './frequently-manuals-item.component.html',
  styleUrls: ['./frequently-manuals-item.component.scss']
})
export class FrequentlyManualsItemComponent implements OnInit {
  @Input() manual: FrequentlyManual;

  constructor() { }

  ngOnInit() {
  }

}

import {SafeHtml, SafeUrl, SafeStyle} from '@angular/platform-browser';

export interface IFrequentlyManual {
  _id: string;
  title: string;
  manualPoster: string;
  manualPosterPreview: SafeStyle;
}

export class FrequentlyManual implements IFrequentlyManual {
  public _id: string;
  public title: string;
  public manualPoster: string;
  public manualPosterPreview: SafeStyle;
  public manualName: string;
  public manualImageSrc: string;

  constructor() {
    this._id = "";
    this.title = "";
    this.manualPoster = "";
    this.manualPosterPreview = ""
  }
}

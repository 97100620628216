
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {IDevice} from './device';
import {config} from '../../../../../configuration';
import {ServerResponse} from './../../shared/server-response';
@Injectable()
export class DeviceService {
  private _devicesUrl = config.apiUrl + '/devices';

  constructor(private _http: HttpClient) { }

  getDevicesNamesFromAPI(): Observable<ServerResponse> {
    return this._http.get(this._devicesUrl + '/public/true').pipe(
      map((response: Response) => <ServerResponse><any>response),
      catchError(this.handleError),);
  }

  getDevicesFromAPI(): Observable<ServerResponse> {
    return this._http.get(this._devicesUrl + '/public/false').pipe(
      map((response: Response) => <ServerResponse><any>response),
      catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error.message);
  }
}

import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { IManual } from "./manual-preview";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

import { config } from "./../../../../configuration";
import { ServerResponse } from "../shared/server-response";

@Injectable()
export class ManualService {
  private _searchManualsURL = `${config.apiUrl}/manuals/search`;
  private _manualsURL = `${config.apiUrl}/manuals`;
  private _getManualURL = `${config.apiUrl}/manuals/preview`;
  private _lastViewedManualsURL = `${config.apiUrl}/manuals`;
  private _addLikeForManual = `${config.apiUrl}/manuals/like`;
  private _addDislikeForManual = `${config.apiUrl}/manuals/dislike`;

  private _headers = new HttpHeaders({ "Content-Type": "application/json" });

  private sample: string[] = [];

  constructor(private _http: HttpClient) {}

  public addSample(value: string) {
    this.sample.push(value);
  }

  public showSamples() {
    console.log("This sample: ", this.sample);
  }

  public getManualsList(queryObject): Observable<ServerResponse> {
    let bodyString = JSON.stringify({
      categoryCode: queryObject.categoryCode,
      devices: queryObject.devices,
      searchQuery: queryObject.searchQuery,
    });

    console.log(bodyString);

    const options = {
      headers: this._headers
    }

    return this._http.post(this._searchManualsURL, bodyString, options).pipe(
      map((response: Response) => {
        return <ServerResponse>(<any>response);
      }),
      catchError(this.handleError)
    );
  }

  public getManual(id: string): Observable<ServerResponse> {
    return this._http.get(`${this._getManualURL}/${id}`).pipe(
      map((response: Response) => <ServerResponse>(<any>response)),
      catchError(this.handleError)
    );
  }

  public getLastViewedManuals(): Observable<IManual[]> {
    return this._http.get(this._lastViewedManualsURL).pipe(
      map((response: Response) => <IManual[]>(<any>response)),
      catchError(this.handleError)
    );
  }

  public addLikeForManual(id: string): Observable<Boolean> {
    let bodyString = JSON.stringify({});
    const options = {
      headers: this._headers
    }
    return this._http
      .put(`${this._addLikeForManual}/${id}`, bodyString, options)
      .pipe(
        map((response: Response) => true),
        catchError(this.handleError)
      );
  }

  public addDislikeForManual(id: string): Observable<Boolean> {
    let bodyString = JSON.stringify({});
    const options = {
      headers: this._headers
    }
    return this._http
      .put(`${this._addDislikeForManual}/${id}`, bodyString, options)
      .pipe(
        map((response: Response) => true),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    return observableThrowError(error.message);
  }
}

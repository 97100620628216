import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsAndConditionsRoutingModule } from './terms-and-conditions-routing.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TermsAndConditionsRoutingModule,
    TranslateModule
  ],
  declarations: [TermsAndConditionsComponent]
})
export class TermsAndConditionsModule { }

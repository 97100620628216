import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CategoryModule} from './category.module';

import {CategoryComponent} from './category.component';

const routes: Routes = [
  {
   path: 'categories',
   component: CategoryComponent
 },
 {
   path: 'categories/:id',
   component: CategoryComponent
 },
 {
   path: 'all-categories/:deviceId',
   component: CategoryComponent
 }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CategoryRoutingModule { }


import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { IFrequentlyManual } from './frequently-manual';
import {ServerResponse} from './../../shared/server-response';

import { config } from './../../../../../configuration';




@Injectable()
export class FrequentlyManualService {
private _lastViewedManualsURL = `${config.apiUrl}/manuals/frequentlyViewed`;

  constructor(private _http: HttpClient) { }

  public getLastViewedManuals(): Observable<ServerResponse> {
      return this._http.get(this._lastViewedManualsURL).pipe(
          map((response: Response) => {
          return <ServerResponse><any>response;
          }),
          catchError(this.handleError),);
  }

  private handleError(error: HttpErrorResponse) {
      return observableThrowError(error.message || 'Server error. Please contact with system administrator.');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManualRoutingModule } from './manual-routing.module';
import { ManualComponent } from './manual.component';
import { ScrollingDirective } from './scrolling-video.directive';

@NgModule({
  imports: [
    CommonModule,
    ManualRoutingModule
  ],
  declarations: [
    ManualComponent,
    ScrollingDirective
  ]
})
export class ManualModule { }

import { Component, OnInit, OnChanges, AfterViewInit, AfterContentInit  } from '@angular/core';

import {FrequentlyManualService} from './frequently-manual.service';

import {IFrequentlyManual} from '../frequently-manuals/frequently-manual';
import {ServerResponse} from './../../shared/server-response';
import { IManual } from '../../manual/manual-preview';

declare var jQuery: any;

@Component({
  selector: 'app-frequently-manuals',
  templateUrl: './frequently-manuals.component.html',
  styleUrls: ['./frequently-manuals.component.scss'],
  providers:[FrequentlyManualService]
})
export class FrequentlyManualsComponent implements OnInit,  OnInit, OnChanges, AfterContentInit, AfterViewInit {
  manuals: IFrequentlyManual[];

  constructor(private _frequentlyManualService: FrequentlyManualService) {

  }

  ngOnInit() {
    this._frequentlyManualService.getLastViewedManuals()
      .subscribe(
        results => {
          this.manuals = results.data;
          this.loadManualsCarousel();
        },
        error => console.log(`Frequently manuals error: ${error}`));
  }

  ngOnChanges(){}
  ngAfterContentInit(){}
  ngAfterViewInit(){}

  loadManualsCarousel(){
      setTimeout(function(){
        jQuery('.sectionManuals__slick').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          nextArrow: '<i class="sectionManuals__slick__arrow"></i>',
          prevArrow: '<i class="sectionManuals__slick__arrow"></i>',
          responsive: [
            {

              breakpoint: 992,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
         ]
       })},100);
  }

}

import { Directive, ElementRef, Renderer, HostListener } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[scrollingVideo]',
    host: {
        '(window:scroll)': 'onScroll()'
    }
})

export class ScrollingDirective {
    el: ElementRef;
    renderer: Renderer;
    elemPostion;
    constructor(el: ElementRef, renderer: Renderer) {
        this.el = el;
        this.renderer = renderer;
    }

    ngOnInit(){}

    onScroll(){
      this.elemPostion = this.el.nativeElement.parentNode.getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientTop;
      //this.el.nativeElement.getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientTop
      // == $('video').offset()
       if (window.pageYOffset > this.elemPostion &&
            window.pageYOffset < (this.elemPostion + this.el.nativeElement.parentNode.offsetHeight) &&
            window.innerWidth > 768) {
            this.el.nativeElement.play();
       } else {
           this.el.nativeElement.pause();
       }
   }
}


import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';





import{IHint} from './hint';
import {config} from '../../../../../configuration';
import {ServerResponse} from './../../shared/server-response';

@Injectable()
export class HintService {
 private _hintsUrl = config.apiUrl + '/hints/public';
  constructor(private _http: HttpClient) {  }

  getHintsFromAPI(): Observable<ServerResponse>{
    return this._http.get(this._hintsUrl).pipe(
    map((response: Response) => {
     return <ServerResponse><any>response}),
    catchError(this.handleError),);
  }

 private handleError(error: HttpErrorResponse){
   return observableThrowError(error.message);
 }

}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml, SafeUrl, SafeStyle } from '@angular/platform-browser';

import { IManual, Manual } from './manual-preview';
import { IDevice } from './../home/devices/device';

import { DeviceService } from './../home/devices/device.service';
import { ManualService } from './manual.service';
import { ScrollingDirective } from './scrolling-video.directive';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [ManualService, DeviceService]
})
export class ManualComponent implements OnInit {
  manualId: string = "";
  manual: IManual;
  showInfoAfterLike: Boolean = false;
  showRelatedManuals: Boolean = false;
  devices: IDevice[] = [];

  constructor(private _route: ActivatedRoute,
    private _manualService: ManualService,
    private sanitizer: DomSanitizer,
    private _deviceService: DeviceService) {
    scroll(0, 0);
  }

  ngOnInit() {

    this.manual = new Manual();

    this._route.params.subscribe(params => {
      if (params["id"] != undefined) {
        this.manualId = params["id"];
        this.getManual();
      }
    },
      error => console.log(`Manual preview error: ${error}`));
  }

  getManual() {
    this._manualService.getManual(this.manualId)
      .subscribe(
      results => {
        this.manual = results.data;
        this.showRelatedManuals = this.manual.relatedManuals.length > 0;
        this.setSafeURL();
        this.setDevices();
      },
      error => {
        console.log(error)
        if(error == 404){
          console.log("redirect must be")
        }
      });
  }

getMediaTypeClass(mediaType){
  return mediaType.toString().replace('photo', 'media');
}

  setDevices() {
    this._deviceService.getDevicesFromAPI()
      .subscribe(
      results => {
        for (let device of results.data) {
          if (this.manual.devices.indexOf(device._id) != -1) {
            this.devices.push(device);
          }
        }
      },
      error => console.log(<string>error)
      );
  }

  setSafeURL() {
    for (let step of this.manual.steps) {
      step.youTubeURLSafe = this.sanitizer.bypassSecurityTrustResourceUrl(step.youTubeURL);
    }
  }

  likeManual(id: string) {
    this._manualService.addLikeForManual(id)
      .subscribe(
      results => {
        this.showInfoAfterLike = true;
      },
      error => console.log(<string>error)
      );
  }

  dislikeManual(id: string) {
    this._manualService.addDislikeForManual(id)
      .subscribe(
      results => {
        this.showInfoAfterLike = true;
      },
      error => console.log(<string>error)
      );
  }

}

import { Component, OnInit, OnChanges, AfterViewInit, AfterContentInit  } from '@angular/core';
import {IDevice, IDeviceSimpleForm} from '../device';
import {DeviceService} from '../device.service';

declare var jQuery: any;

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss'],
  providers: [DeviceService]
})
export class DevicesListComponent implements OnInit, OnInit, OnChanges, AfterContentInit, AfterViewInit {
  devices: IDevice[];
  devicesNames: IDeviceSimpleForm[];

  constructor(private _deviceService: DeviceService) { }

  ngOnInit() {
    this._deviceService.getDevicesFromAPI()
      .subscribe(
      results => {
        this.devices = results.data;
        this.loadDevicesCarousel();
      },
      error => console.log(`Devices error: ${error}`));
  }


  ngOnChanges() { }
  ngAfterContentInit() { }
  ngAfterViewInit() { }

  createImgURL(img) {
    return "url(" + img + ")";
  }

  loadDevicesCarousel() {
    setTimeout(function() {
      jQuery('.sectionPhotos__slick').slick({
        slidesToShow: 2,
        centerPadding: '60px',
        slidesToScroll: 1,
        nextArrow: '<i class="sectionPhotos__slick__arrow"></i>',
        prevArrow: '<i class="sectionPhotos__slick__arrow"></i>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      })
    }, 100);

  }



}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {SimpleNotificationsModule }  from 'angular2-notifications';

import { RequestMaintenanceRoutingModule } from './request-maintenance-routing.module';
import { RequestMaintenanceComponent } from './request-maintenance.component';

@NgModule({
  imports: [
    CommonModule,
    RequestMaintenanceRoutingModule,
    FormsModule,
    SimpleNotificationsModule
  ],
  declarations: [RequestMaintenanceComponent]
})
export class RequestMaintenanceModule { }

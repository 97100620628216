import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../common/auth.guard';

//Errors
import{PageNotFoundComponentComponent} from './errors/page-not-found-component/page-not-found-component.component';



/**
 * Route constant
 */
const routes: Routes = [
    { path: '**', component: PageNotFoundComponentComponent }

];

@NgModule({
  declarations:[
    PageNotFoundComponentComponent
  ],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

import { Component, OnInit, OnChanges, Input, AfterViewInit, AfterContentInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HintService } from './hint.service';
import { IHint } from './hint';

declare var jQuery: any;

@Component({
    selector: 'app-hints',
    templateUrl: './hints.component.html',
    styleUrls: ['./hints.component.scss'],
    providers: [HintService]
})
export class HintsComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
    hints: IHint[] = [];
    pHints: object;
    constructor(private _hintService: HintService) {
    }

    ngOnInit() {
        this._hintService.getHintsFromAPI()
            .subscribe(
            results => this.hints = results.data,
            error => console.log(`Hint error: ${error}`),
            () => this.loadHintsCarousel()
            );



    }
    loadHintsCarousel() {
        setTimeout(function() {
            jQuery('.sectionSentences__slick').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                speed: 1200,
                nextArrow: '<i class="sectionSentences__slick__arrow"></i>',
                prevArrow: '<i class="sectionSentences__slick__arrow"></i>'
            });
        }, 200);

    }
    ngOnChanges(changes: any) {
    }

    ngAfterContentInit() {
    }

    ngAfterViewInit() {
    }
}


import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";





import { IRequest } from './request';

import {config} from '../../../../configuration';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class RequestMaintenanceService {
 private _requestUrl =   config.apiUrl + "/requests/";
 private _headers = new HttpHeaders({'Content-Type': 'application/json'});
 
 _id: string;

  constructor(private _http: HttpClient) {  }

  getRequestFromAPI(id: string): Observable<IRequest>{
    return this._http.get(this._requestUrl + id).pipe(
    map((response: Response) => <IRequest><any>response ),
    catchError(this.handleError),);
  }


  update(request: IRequest): Promise<IRequest> {
    const url = `${this._requestUrl}${request._id}`;
    return this._http
      .put(url, JSON.stringify(request), {headers: this._headers})
      .toPromise()
      .then(() => request)
      // FIXME simplyfying error handling should be acceptable in this case
      // .catch(this.handleError);
  }
  create(request: IRequest): Observable<String> {
   return this._http.post(this._requestUrl, { request }, { headers: this._headers}).pipe(
                   map((response: Response) => response),
                   catchError(this.handleError),);
 }

 private handleError(error: HttpErrorResponse){
   return observableThrowError(error.message || 'Server error');
 }
}

import { Component, OnInit, ViewChild, OnChanges, AfterViewInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceService } from './devices/device.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from "@ngx-translate/core";

import {ManualService} from '../manual/manual.service';

declare var jQuery: any;

import { IDevice, IDeviceSimpleForm } from "./devices/device";
import {ServerResponse} from './../shared/server-response';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DeviceService, ManualService]
})
export class HomeComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
  searchQuery: string;
  private selectedDevices: number[] = [];
  devices: IDeviceSimpleForm[] = [];

  constructor(private router: Router,
    private deviceService: DeviceService,
    private _notificationsService: NotificationsService,
    private _manualsService: ManualService ) {
        scroll(0,0);
    }

  ngOnInit() {
    this.deviceService.getDevicesNamesFromAPI()
      .subscribe(
        results => this.devices = results.data,
        error => console.log(`Devices error: ${error}`));

    this.loadSearchControl();
  }
  ngOnChanges() { }
  ngAfterContentInit() { }
  ngAfterViewInit() { }

  loadSearchControl() {
    setTimeout(function() {

      jQuery('.sectionSearch__content').click(function() {
        jQuery(this).addClass('active');
        jQuery('.sectionSearch__input').focus();
      });

      jQuery('.sectionSearch__closeIcon').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        jQuery(this).parents('.sectionSearch__content').removeClass('active');
      });
    }, 200);

  }

  search() {
    if (this.searchQuery != undefined) {
      this._manualsService.addSample(this.searchQuery);
      localStorage.removeItem('searchParams');
      localStorage.setItem('searchParams', JSON.stringify({ query: this.searchQuery, devices: this.selectedDevices }))
      this.router.navigate(['/categories']);
    }
    else {
      this.showErrorMessage("Search text is empty!");
    }
  }

  addDeviceToSearchList(device: number) {
    var index = this.selectedDevices.indexOf(device, 0);
    if (index > -1) {
      this.selectedDevices.splice(index, 1);
    }
    else {
      this.selectedDevices.push(device);
    }
  }

  isDeviceSelected(device: number): boolean {
    var index = this.selectedDevices.indexOf(device, 0);
    if (index > -1) {
      return true;
    }
    return false;
  }

  /* Notyfications */

  showSuccessMessage(text: string) {
    this._notificationsService.success(
      'SUCCESS',
      text,
      {
        pauseOnHover: false,
        clickToClose: true
      }
    )
  }
  showWarningMessage(text: string) {
    this._notificationsService.warn(
      'WARNING',
      text,
      {
        pauseOnHover: false,
        clickToClose: true
      }
    )
  }
  showErrorMessage(text: string) {
    this._notificationsService.error(
      'ERROR',
      text,
      {
        pauseOnHover: false,
        clickToClose: true
      }
    )
  }



}

import { Component, OnInit, Input } from '@angular/core';
import {IHint} from '../hint';

@Component({
  selector: 'app-hints-element',
  templateUrl: './hints-element.component.html',
  styleUrls: ['./hints-element.component.scss']
})
export class HintsElementComponent implements OnInit {
 @Input() hint: IHint;

  constructor() { }

  ngOnInit() {
  }

}
